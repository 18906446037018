import {HarvestCrop, LossCause, Sample} from '../models/interfaces';
import {AreaValue, Loss, YieldUnit, YieldValue} from '../models/types';
import {aggregate, filterNulls, remove} from '../util/arr-util';
import {convertArea, convertYield} from './units';

// This interface describes four variables that describe the amount of loss, if any, on a farm.
export interface YieldStats {
  // The units for the values below. May be 'percent' iff only total Loss is filled.
  unit: YieldUnit;

  // For samples, this is the affected area covered by that sample.
  // For field aggregations, this is the area of the field, and for farm aggregations, the total area of all fields.
  areaHa: null | number;

  insuredAreaHa: null | number;

  // For samples and fields: the insured yield of the field. For farms: the weighted average by field area.
  insured: null | number;

  // For samples, this is the feasible yield for the given area, else the insured yield.
  // For field aggregations, it is the weighted average by affected area. If affected areas are missing the residual
  // area is split evenly among the remaining samples with a feasible yield; else the insured yield; else null.
  // At the farm level, it is the weighted average by field area.
  feasible: null | number;

  // The estimated yield is aggregated in the same way as the feasible yield. When aggregating the estimated yield,
  // the residual area is assumed to achieve the feasible yield.
  estimated: null | number;

  // For samples, this should always be null, because we first need to aggregate by loss cause across the field.
  totalLoss: null | number;

  // BACKFILLING VALUES
  // At the sample level we will attempt to back fill values, as follows:
  // * if the feasible yield is missing, we will use the insured yield
  // * if one of feasible, estimated, or totalLoss is missing, we will attempt to backfill it using the other two values
  //   including the backfilled feasible yield.

  // For samples, the amount of loss for each cause, in the given unit.
  // For fields/farms, we first aggregate by loss.
  lossByCause: null | {[P in LossCause]?: number};

  // For samples, this is the sampled_area, which can either simply be sample.sample_area or the residual area,
  // which is calculated below. Effectively, sampledAreaHa must be equal to the areaHa if there is at least one sample.
  // If there are no samples, it should be null.
  //
  // For harvests, it's the sum of its samples sampleAreaHa.
  // For farms, it's the sum of its harvests sampleAreaHa.
  sampledAreaHa: null | number;
}

export type SampleColumns = Partial<
  Pick<
    Sample,
    'sample_id' | 'estimated_yield' | 'feasible_yield' | 'sample_location' | 'sample_area' | 'sample_date' | 'losses'
  >
>;

// Calculates the aggregate yield (estimated, feasible, lost) across a set of samples, using the following methodology.
export function calculateYieldStats(
  defaultYieldUnit: YieldUnit,
  area: AreaValue | null,
  harvest_crop: null | HarvestCrop,
  insured_yield: null | YieldValue,
  insured_area: null | AreaValue,
  samples: SampleColumns[],
  excludeSamplesWithEmptyLossInAggregation?: boolean,
): YieldStats & {data: (YieldStats & {sample_id: string | null})[]} {
  // First, all yield values are checked and unified to one type of unit, to simplify all downstream calculations; if
  // all data uses the same unit, then it is kept; otherwise a fallback unit is selected using the UnitSystem.
  // Area values are always converted to hectares, as they are only used as weights in this function.
  const distinctUnits: YieldUnit[] = Array.from(
    new Set(
      filterNulls([insured_yield, ...samples.map(x => [x.estimated_yield, x.feasible_yield]).flat(1)]).map(x => x.unit),
    ),
  );
  let unit: YieldUnit = distinctUnits.length == 1 ? distinctUnits[0] : defaultYieldUnit;
  if (distinctUnits.length == 0) {
    // Special case - if this is a percent loss sample, with no other values, set the unit to 'percent'.
    const lossUnits: YieldUnit[] = filterNulls(
      Array.from(new Set(samples.map(sample => sample.losses?.map(loss => loss.loss?.unit)).flat(1))),
    );
    if (lossUnits.length == 1 && lossUnits[0] == 'percent') {
      unit = 'percent';
    }
  }

  const maxSampleDate = Math.max(
    ...filterNulls(samples.map(x => (x.sample_date ? new Date(x.sample_date).getTime() : null))),
  );
  const insured = convertYield(unit, insured_yield, harvest_crop)?.val ?? null;

  var overrideIndex: number | null = null;

  const data: (YieldStats & {sample_id: string | null; finalVisit: boolean})[] = samples.map((s, idx) => {
    const daysBeforeMax = s.sample_date
      ? (maxSampleDate - new Date(s.sample_date).getTime()) / (24 * 60 * 60 * 1000)
      : null;
    const finalVisit: boolean = daysBeforeMax != null ? daysBeforeMax <= 14 : false;
    const areaHa = convertArea('hectares', s.sample_area ?? null)?.val || null;
    const insuredAreaHa = convertArea('hectares', insured_area)?.val || null;
    // On the sample level, areaHa and sampledAreaHa are the same.
    const sampledAreaHa = areaHa;
    let totalLoss: null | YieldValue = s.losses ? getTotalSampleLoss(s.losses) : null;

    if (!s.sample_location && !s.sample_area && finalVisit) {
      overrideIndex = idx;
    }

    if (unit == 'percent') {
      return {
        unit: 'percent',
        totalLoss: totalLoss?.val ?? null,
        areaHa,
        insuredAreaHa,
        sampledAreaHa,
        finalVisit,
        lossByCause: getSampleLosses(s.losses, 'percent', harvest_crop, null),
        feasible: null,
        estimated: null,
        insured: null,
        sample_id: s.sample_id ?? null,
      };
    }

    let estimated: null | number = convertYield(unit, s.estimated_yield ?? null, harvest_crop)?.val ?? null;
    let feasible: null | number = convertYield(unit, s.feasible_yield ?? null, harvest_crop)?.val ?? insured;

    if (totalLoss?.unit == 'percent' && feasible) {
      totalLoss = {unit, val: (feasible * totalLoss.val) / 100};
    } else if (totalLoss?.unit == 'percent' && estimated) {
      // totalYieldLoss = feasible - estimated          || estimated = feasible * (1 - totalPercentLoss)
      //                = estimated / (1 - totalPercentLoss) - estimated
      //                = estimated x (1/(1-totalPercentLoss) - 1)
      //
      // If the totalLoss is 100%, and we have an estimated yield > 0, we cannot calculate the totalLoss value,
      // as division by zero is undefined. In this case, we set totalLoss to null.
      totalLoss =
        totalLoss.val === 100 && estimated > 0 ? null : {unit, val: estimated * (1 / (1 - totalLoss.val / 100) - 1)};
    } else if (totalLoss?.unit != unit) {
      totalLoss = convertYield(unit, totalLoss, harvest_crop);
    }

    if (feasible == null && estimated != null && totalLoss != null) {
      feasible = estimated + totalLoss.val;
    }
    if (estimated == null && feasible != null && totalLoss != null) {
      estimated = feasible - totalLoss.val;
    }

    const lossByCause = getSampleLosses(s.losses, unit, harvest_crop, feasible);

    return {
      unit,
      areaHa,
      insuredAreaHa,
      estimated,
      feasible,
      lossByCause,
      finalVisit,
      insured,
      sampledAreaHa,
      totalLoss: totalLoss?.val ?? null,
      sample_id: s.sample_id ?? null,
    };
  });

  // Then, we only keep the latest samples (within a two-week window), and we aggregate the stats at the field level,
  // taking the weighted average by affected area, using the methodology in calculateYieldStats.
  const finalVisitData = data.filter(x => x.finalVisit);

  // The only reason to use finalVisitData here, is to make sure to only use the samples within the two-week window.
  const actuallySampledAreasHa = finalVisitData.map(x => x.sampledAreaHa).filter(remove.nulls) as number[];
  // In case we have no harvest_area, we assume the area to be equal to the sum of the sampled areas. We cannot fall
  // back to the insured_area, as the context of the insured_area (farm-level harvest) and the context of this
  // calculation may not match (i.e. it could be that we are calculating a field-level harvest here).
  const areaHa =
    convertArea('hectares', area)?.val ??
    (actuallySampledAreasHa.length == samples.length ? actuallySampledAreasHa.reduce(...aggregate.sum) ?? 0 : null);
  const insuredAreaHa = convertArea('hectares', insured_area)?.val ?? null;

  let feasible =
    averageByAffectedArea(
      areaHa,
      insured,
      finalVisitData.map(x => ({
        affectedAreaHa: x.areaHa,
        value: x.feasible,
      })),
    ) ?? insured;

  const lossCauses: Set<LossCause> = new Set(
    finalVisitData.map(x => Object.keys(x.lossByCause ?? {}) as LossCause[]).flat(1),
  );
  let lossByCause: null | {[P in LossCause]?: number} = {};
  let totalLoss: null | number = 0;
  for (const lossCause of lossCauses) {
    // Aggregate visits by loss cause first.
    const lossData = finalVisitData.map(x => ({
      affectedAreaHa: x.areaHa,
      value: x.lossByCause?.[lossCause] ?? (excludeSamplesWithEmptyLossInAggregation ? null : 0),
    }));
    lossByCause[lossCause] = averageByAffectedArea(areaHa, 0, lossData) ?? undefined;
    if (lossByCause[lossCause] == undefined) {
      totalLoss = null;
    }
    if (totalLoss != null) {
      totalLoss += lossByCause[lossCause] ?? 0;
    }
  }

  // The flag "excludeSamplesWithEmptyLossInAggregation" effectively means a loss of a given cause in one sample,
  // (if the same loss cause is not used on any other sample in the field) will be interpreted as having been present
  // on all samples (with the same values).
  // This, in effect, means, we cannot average the calculated estimated yields, as the calculated estimated yield
  // on the sample without that loss cause does not know about the loss defined on another sample.
  // To work around this, we will calculate the estimated yield in this case as the feasible yield minus the total loss.
  let estimated = excludeSamplesWithEmptyLossInAggregation
    ? feasible != null && totalLoss != null && finalVisitData.length
      ? feasible - totalLoss
      : null
    : averageByAffectedArea(
        areaHa,
        feasible,
        finalVisitData.map(x => ({
          affectedAreaHa: x.areaHa,
          value: x.estimated,
        })),
      );

  // Complement finalVisitData with effective area used for the computation of the averages + add an entry representing
  // the residual used to complete the calculation over the whole field.
  // TODO(sad): Potentially use this to display in the app, e.g. with an infobox next to the overall estimated yield,
  //  a breakdown of how said estimated yield is computed.
  const totalAffectedArea = finalVisitData.map(x => x.sampledAreaHa).reduce(...aggregate.sum) ?? 0;
  const residualArea = areaHa ? Math.max(0, areaHa - totalAffectedArea) : 0;
  const numSamplesWithoutAffectedArea = finalVisitData.filter(x => x.sampledAreaHa == null).length;
  const residualAreaPerSample = numSamplesWithoutAffectedArea > 0 ? residualArea / numSamplesWithoutAffectedArea : 0;
  if (residualArea > 0) {
    if (numSamplesWithoutAffectedArea > 0) {
      // Distribute the residual area over all samples without sample_area.
      for (const data of finalVisitData) {
        if (data.areaHa == null) {
          data.areaHa = data.sampledAreaHa = residualAreaPerSample;
        }
      }
    } else {
      // If we cannot distribute the residual area over existing samples without area,
      // we generate a dummy sample to make up for that remaining area.
      finalVisitData.push({
        unit,
        areaHa: residualArea,
        insuredAreaHa: null,
        estimated: estimated ? feasible : null,
        feasible: feasible ? insured : null,
        lossByCause: {},
        finalVisit: true,
        insured,
        sampledAreaHa: residualArea,
        totalLoss: 0,
        sample_id: null,
      });
    }
  }

  // Field-level samples act as override
  if (overrideIndex != null) {
    const override = data[overrideIndex];
    if (override.estimated != null) {
      estimated = override.estimated;
    }
    if (override.feasible) {
      feasible = override.feasible;
    }
    if (override.lossByCause) {
      const losses = Object.values(override.lossByCause);
      if (losses.length > 0) {
        lossByCause = override.lossByCause;
        totalLoss = Object.values(lossByCause).reduce((a, b) => a + b, 0);
      }
    }
  }

  if (data.length == 0) {
    totalLoss = null;
    lossByCause = null;
  }

  // We need to aggregate sampledAreaHa based on finalVisitData as we want the sampledAreaHa to also include any
  // residual area that was (re-)distributed to the samples.
  // But, we can only aggregate the sampleArea from the finalVisitData, if there is at least one sample. As else there
  // is a dummy entry for the whole area in the finalVisitData but as there never was any sampling done, it would be
  // wrong to aggregate that area.
  const sampledAreaHa = samples.length > 0 ? finalVisitData.map(d => d.sampledAreaHa).reduce(...aggregate.sum) : null;

  return {
    unit,
    areaHa,
    insuredAreaHa,
    insured,
    estimated,
    feasible,
    lossByCause,
    totalLoss,
    data: finalVisitData,
    sampledAreaHa,
  };
}

// This method calculates the weighted averages of the array of values, using the affected area as the weight.
// Samples without a value are discarded, as they are unusable.
// If the sum of usable affected areas exceeds the field area, then the samples are used as weights.
// If the sum of usable affected areas is below the field area, then the residual area:
//  * is split amongst samples without an affected area, if any;
//  * else, the `residualValue` is applied to the residual area.
export function averageByAffectedArea(
  fieldArea: null | number,
  residualValue: null | number,
  arr: {affectedAreaHa: null | number; value: null | number}[],
) {
  const usableValues = arr.filter(x => x.value != null) as {affectedAreaHa: number | null; value: number}[];
  if (usableValues.length == 0) {
    return null;
  }
  const valuesWithArea = usableValues.filter(x => x.affectedAreaHa != null) as {
    affectedAreaHa: number;
    value: number;
  }[];
  const valuesWithoutArea = usableValues.filter(x => x.affectedAreaHa == null).map(x => x.value) as number[];

  // If we only have a set of samples without an affected area, return a simple average
  if (valuesWithArea.length == 0 && valuesWithoutArea.length > 0) {
    return valuesWithoutArea.reduce((acc, x) => acc + x, 0) / valuesWithoutArea.length;
  }

  const totalUsableAffectedArea = valuesWithArea.reduce((acc, x) => acc + x.affectedAreaHa, 0);

  const residualArea = fieldArea ? Math.max(0, fieldArea - totalUsableAffectedArea) : 0;
  const residualAreaPerSample = valuesWithoutArea.length > 0 ? residualArea / valuesWithoutArea.length : 0;

  const adjustmentFactor = Math.max(1, fieldArea ? totalUsableAffectedArea / fieldArea : 0);

  let sum = 0,
    weight = 0,
    residualAffectedAreaHa = fieldArea || 0;
  for (const {value, affectedAreaHa} of usableValues) {
    const adjustedAffectedArea = affectedAreaHa == null ? residualAreaPerSample : affectedAreaHa / adjustmentFactor;
    residualAffectedAreaHa -= adjustedAffectedArea;
    sum += adjustedAffectedArea * value;
    weight += adjustedAffectedArea;
  }

  if (weight == 0) {
    return null;
  }

  if (residualAffectedAreaHa > 0) {
    if (residualValue != null) {
      sum += residualValue * residualAffectedAreaHa;
      weight += residualAffectedAreaHa;
    }
  }

  return sum / weight;
}

// Returns the sample's losses by type, in the target unit. Returns null, if any loss value is missing.
export function getSampleLosses(
  losses: undefined | Loss[],
  unit: YieldUnit,
  harvest_crop: null | HarvestCrop,
  feasible: null | number,
): null | {[P in LossCause]?: number} {
  let yieldLosses: {[P in LossCause]?: number} = {};
  for (const {loss, loss_cause} of losses ?? []) {
    if (!loss) {
      continue;
    }

    if (loss.unit == 'percent' && unit != 'percent') {
      if (feasible) {
        yieldLosses[loss_cause ?? 'unknown'] =
          (yieldLosses[loss_cause ?? 'unknown'] ?? 0) + (loss.val / 100) * feasible;
      } else {
        return null; // Can't calculate this without the feasible yield.
      }
    } else {
      const yldLoss = convertYield(unit, loss, harvest_crop);
      if (!yldLoss) {
        return null;
      }
      // Sum up losses of the same cause
      // (e.g. sometimes the same loss event may happen twice, on different dates)
      yieldLosses[loss_cause ?? 'unknown'] = (yieldLosses[loss_cause ?? 'unknown'] ?? 0) + yldLoss.val;
    }
  }

  return yieldLosses;
}

export function getTotalSampleLoss(losses: Loss[]): null | YieldValue {
  if (losses.length == 0) {
    return {unit: 'percent', val: 0};
  }

  const lossUnits = Array.from(new Set(filterNulls(losses.map(x => x.loss?.unit))));
  if (lossUnits.length != 1) {
    return null;
  }

  let totalLoss = 0;
  for (const {loss, loss_cause} of losses) {
    if (loss_cause == 'none') {
      continue;
    }
    // User enter a loss with a loss cause, but no value (e.g. to note damage without an estimation)
    // skip this entry and continue processing the other ones
    if (loss == null) {
      continue;
    }
    totalLoss += loss.val;
  }
  return {unit: lossUnits[0], val: totalLoss};
}

export function getTotalSampleLossNonPercent(
  feasible: null | YieldValue,
  estimated: null | YieldValue,
  losses: Loss[],
): null | YieldValue {
  const totalLosses = getTotalSampleLoss(losses);
  if (totalLosses?.unit != 'percent') {
    return totalLosses;
  }

  const lossRatio = totalLosses.val / 100;
  if (feasible) {
    return {unit: feasible.unit, val: feasible.val * lossRatio};
  }

  if (estimated) {
    return {unit: estimated.unit, val: estimated.val * (1 / (1 - lossRatio) - 1)};
  }

  return null;
}
